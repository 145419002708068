<script setup>
const props = defineProps({
  data: Object
})

// Adding tags array to form
if (props.data.leadGenForm) {
  props.data.leadGenForm.tagsArray = props.data.tags
}


</script>
<template>
  <div class="block full-width-form">
    <div class="container">
      <div class="row">
        <div class="col">
          <!-- Lead type ID for lead gen full width form -->
          <LayoutLongForm :leadGenForm="data.leadGenForm" :sharedContent="data.sharedContent" :leadTypeID="'67011'" :tags="props.data.tags"/> 
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">

</style>